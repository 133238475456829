<template>
  <b-col
    :lg="whichPage === 'listings' || whichPage === 'list_event' ? 12 : 4"
    class="d-flex align-items-center auth-bg px-2 p-lg-2"
  >
    <b-col
      sm="8"
      md="6"
      lg="12"
      class="px-xl-2 mx-auto"
    >
      <b-card-title
        title-tag="h2"
        class="font-weight-bold mb-1"
      >
        Permissions requested
      </b-card-title>
      <b-card-text class="mb-2 font-weight-bolder">
        by {{ appName }}
      </b-card-text>

      <b-card-text class="my-2 font-weight-bold">
        This application would like to:
      </b-card-text>

      <b-card-text class="my-2 font-weight-bold">
        <ul class="ml-2">
          <li>Read your e2i jobs profile details</li>

        </ul>
      </b-card-text>
      <b-card-text class="my-2">
        Accepting these permission(s) means that you allow this app to use your data as specified in their terms of service.
        You can remove this or any other app connected to your account in <a href="/profile">My Profile</a>.
      </b-card-text>
      <!-- form -->
      <b-row>
        <b-col>
          <b-button
            type="submit"
            variant="danger"
            block
            @click="decline"
          >
            Decline
          </b-button>
        </b-col>
        <!-- submit buttons -->
        <b-col>
          <SpinnerBtn v-if="isLoading" />
          <b-button
            v-else
            type="submit"
            variant="success"
            block
            @click="accept"
          >
            Accept
          </b-button>
        </b-col>
      </b-row>

    </b-col>
  </b-col>
</template>

<script>
/* eslint-disable global-require */

import SpinnerBtn from '@/layouts/components/spinners/SpinnerButton.vue'

export default {
  components: {

    SpinnerBtn,
  },
  props: {
    whichPage: { type: String, default: 'normal' },
    federatedId: { type: String, default: null },
  },
  data() {
    return {
      isLoading: false,
      appName: 'e2i Bot',
      appUrl: '',
    }
  },

  created() {
    const fedInfo = JSON.parse(localStorage.getItem('FEDERATION_INFO'))
    this.appUrl = fedInfo.bot_link.replace('?start=', '')
    this.appName = fedInfo.bot_name
    // this.recaptcha()
  },
  methods: {
    accept() {
      this.isLoading = true
      //const emailAddress = this.$store.state.auth.ActiveUser.email
      this.$http
        .get(`/api/approve-app/${this.federatedId}`)
        .then(response => {
          if (response.data.success) {
            this.$toast(
              {
                component: this.$toastContent,
                props: {
                  title: 'App Login',
                  icon: 'AlertCircleIcon',
                  text: `Permissions accepted. ${this.appName} will be able to access your account information.`,
                  variant: 'success',
                },
              },
              { timeout: this.$longestTimeout },
            )
            window.location.href = this.appUrl
          }
        })
        .catch(error => {})
    },
    decline() {
      this.$toast(
        {
          component: this.$toastContent,
          props: {
            title: 'App Login',
            icon: 'AlertCircleIcon',
            text: `Permissions denied. ${this.appName} will not be able to access your account information.`,
            variant: 'danger',
          },
        },
        { timeout: this.$longestTimeout },
      )
      window.location.href = this.appUrl
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
